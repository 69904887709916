/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
				var isLateralNavAnimating = false;

				// open/close lateral navigation
				$('.menu-drawer').on('click', function(event){
					event.preventDefault();
					//stop if nav animation is running
					if( !isLateralNavAnimating ) {
						if($(this).parents('.csstransitions').length > 0 ) {isLateralNavAnimating = true;
						}
						$('body').toggleClass('navigation-is-open');
						$('.nav-wrapper').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
							//animation is over
							isLateralNavAnimating = false;
						});
					}
				});

				// LocalScroll
				// $('.feature.module').localScroll();
				$.localScroll(
					{offset: -100}
				);

				// Initialize Glide.js Slider
				$(".infobox__slider").glide({
					type: "carousel",
					autoplay: false,
				});

				// Masonry
				$('.grid').masonry({
					// options
					itemSelector: '.grid-item',
					columnWidth: '.grid-sizer'
				});

				// Video load on bigger windows only
				if(document.body.clientWidth >= 992) {
						$('video').attr('autoplay', true);
						$('video').attr('preload', 'auto');
				}

				$(window).resize(function() {
						if(document.body.clientWidth >= 992) {
								$('video').attr('autoplay', true);
								$('video').attr('preload', 'auto');
						}
				});
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		'taxi-bestellen': {
			init: function() {
				// JavaScript to be fired on the order page

				// jQuery Datepicker
				var dateToday = new Date();

				$(".datepicker").datepicker({
					inline: true,
					showOtherMonths: true,
					changeMonth: false,
					minDate: dateToday
				});

				$.datepicker.regional['de'] = {
					clearText: 'löschen', clearStatus: 'aktuelles Datum löschen',
					closeText: 'schließen', closeStatus: 'ohne Änderungen schließen',
					prevText: '<zurück', prevStatus: 'letzten Monat zeigen',
					nextText: 'Vor>', nextStatus: 'nächsten Monat zeigen',
					currentText: 'heute', currentStatus: '',
					monthNames: ['Januar','Februar','März','April','Mai','Juni',
					'Juli','August','September','Oktober','November','Dezember'],
					monthNamesShort: ['Jan','Feb','Mär','Apr','Mai','Jun',
					'Jul','Aug','Sep','Okt','Nov','Dez'],
					monthStatus: 'anderen Monat anzeigen', yearStatus: 'anderes Jahr anzeigen',
					weekHeader: 'Wo', weekStatus: 'Woche des Monats',
					dayNames: ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
					dayNamesShort: ['So','Mo','Di','Mi','Do','Fr','Sa'],
					dayNamesMin: ['So','Mo','Di','Mi','Do','Fr','Sa'],
					dayStatus: 'Setze DD als ersten Wochentag', dateStatus: 'Wähle D, M d',
					dateFormat: 'dd.mm.yy', firstDay: 1,
					initStatus: 'Wähle ein Datum', isRTL: false
				};

				$.datepicker.setDefaults($.datepicker.regional['de']);
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

// Google Maps API w ACF and async
// renderMap
function initialize() {
	function renderMap($el) {
		// var
		var $markers = $el.find('.map__marker');

		var styles = [
		{
				 "featureType": "all",
				 "elementType": "labels.text.fill",
				 "stylers": [
						 {
								 "saturation": 36
						 },
						 {
								 "color": "#333333"
						 },
						 {
								 "lightness": 40
						 }
				 ]
		 },
		 {
				 "featureType": "all",
				 "elementType": "labels.text.stroke",
				 "stylers": [
						 {
								 "visibility": "on"
						 },
						 {
								 "color": "#ffffff"
						 },
						 {
								 "lightness": 16
						 }
				 ]
		 },
		 {
				 "featureType": "all",
				 "elementType": "labels.icon",
				 "stylers": [
						 {
								 "visibility": "off"
						 }
				 ]
		 },
		 {
				 "featureType": "administrative",
				 "elementType": "geometry.fill",
				 "stylers": [
						 {
								 "color": "#fefefe"
						 },
						 {
								 "lightness": 20
						 }
				 ]
		 },
		 {
				 "featureType": "administrative",
				 "elementType": "geometry.stroke",
				 "stylers": [
						 {
								 "color": "#fefefe"
						 },
						 {
								 "lightness": 17
						 },
						 {
								 "weight": 1.2
						 }
				 ]
		 },
		 {
				 "featureType": "landscape",
				 "elementType": "geometry",
				 "stylers": [
						 {
								 "color": "#f5f5f5"
						 },
						 {
								 "lightness": 20
						 }
				 ]
		 },
		 {
				 "featureType": "poi",
				 "elementType": "geometry",
				 "stylers": [
						 {
								 "color": "#f5f5f5"
						 },
						 {
								 "lightness": 21
						 }
				 ]
		 },
		 {
				 "featureType": "poi.park",
				 "elementType": "geometry",
				 "stylers": [
						 {
								 "color": "#dedede"
						 },
						 {
								 "lightness": 21
						 }
				 ]
		 },
		 {
				 "featureType": "road.highway",
				 "elementType": "geometry.fill",
				 "stylers": [
						 {
								 "color": "#ffffff"
						 },
						 {
								 "lightness": 17
						 }
				 ]
		 },
		 {
				 "featureType": "road.highway",
				 "elementType": "geometry.stroke",
				 "stylers": [
						 {
								 "color": "#ffffff"
						 },
						 {
								 "lightness": 29
						 },
						 {
								 "weight": 0.2
						 }
				 ]
		 },
		 {
				 "featureType": "road.arterial",
				 "elementType": "geometry",
				 "stylers": [
						 {
								 "color": "#ffffff"
						 },
						 {
								 "lightness": 18
						 }
				 ]
		 },
		 {
				 "featureType": "road.local",
				 "elementType": "geometry",
				 "stylers": [
						 {
								 "color": "#ffffff"
						 },
						 {
								 "lightness": 16
						 }
				 ]
		 },
		 {
				 "featureType": "transit",
				 "elementType": "geometry",
				 "stylers": [
						 {
								 "color": "#f2f2f2"
						 },
						 {
								 "lightness": 19
						 }
				 ]
		 },
		 {
				 "featureType": "water",
				 "elementType": "geometry",
				 "stylers": [
						 {
								 "color": "#e9e9e9"
						 },
						 {
								 "lightness": 17
						 }
				 ]
		 }

		];

		// vars
		var args = {
			zoom    		: 16,
			center    	: new google.maps.LatLng(0, 0),
			scrollwheel: false,
			mapTypeControlOptions: {
				mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'taxiMap']
			}
		};

		// create map
		var map = new google.maps.Map( $el[0], args);

		// styling
		var styledMapOptions = {
			name: 'Greyscale'
		};

		var taxiMapType = new google.maps.StyledMapType(styles, styledMapOptions);

		map.mapTypes.set('taxiMap', taxiMapType);
		map.setMapTypeId('taxiMap');

		// add a markers reference
		map.markers = [];

		// add markers
		$markers.each(function(){
			addMarker(jQuery(this), map);
		});

		// center map
		centerMap(map);
	}

	// addMarker
	function addMarker($marker, map) {
		// var
		var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

		// create marker
		var marker = new google.maps.Marker({
			position  : latlng,
			map     : map
		});

		// add to array
		map.markers.push(marker);

		// if marker contains HTML, add it to an infoWindow
		if ($marker.html()) {
			// create info window
			var infowindow = new google.maps.InfoWindow({
				content   : $marker.html()
			});

			// show info window when marker is clicked
			google.maps.event.addListener(marker, 'click', function() {
				infowindow.open( map, marker );
			});
		}

	}

	// centerMap
	function centerMap(map) {

		// vars
		var bounds = new google.maps.LatLngBounds();

		// loop through all markers and create bounds
		jQuery.each(map.markers, function(i, marker){
			var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
			bounds.extend( latlng );
		});

		// only 1 marker?
		if (map.markers.length === 1) {
			// set center of map
			map.setCenter( bounds.getCenter());
			map.setZoom( 15 );
		} else {
			// fit to bounds
			map.fitBounds( bounds );
		}
	}

	jQuery('.map__container').each(function() {
		renderMap(jQuery(this));
	});
}

function loadScript() {
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp' +
	'&signed_in=true&callback=initialize';
	document.body.appendChild(script);
}

window.onload = loadScript;
